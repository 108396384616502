import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer/footer";
import Header from "../../component/Header/Header";

const CLOUDINARY_URL = process.env.REACT_APP_PRIVACY_DOC;

const Privacy = (): JSX.Element => {
  const [htmlContent, setHtmlContent] = useState<any>();

  const Banner = (): JSX.Element => {
    return (
      <div className="w-full flex flex-row items-center justify-center h-96 privacy-banner mb-6">
        <h2 className="font-InterExtraBold font-extrabold text-regularWhite text-4xl leading-10">
          Privacy Policy
        </h2>
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://res.cloudinary.com/dreezy/raw/upload/v1741118083/Tanda_Secure_Privacy_Policy_f7vhor.html`);
        const htmlDoc: any = await response.text();
        if (htmlDoc) {
          setHtmlContent(htmlDoc);
        }
      } catch (error) {
        return error;
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Header query="PRIVACY" />
      <Banner />
      <div
        className="w-4/5 md:w-2/3 lg:w-2/3 xl:w-1/2 mx-auto py-5 mt-12 mb-12 font-InterRegular text-regularGrey font-normal"
        dangerouslySetInnerHTML={{
          __html: htmlContent,
        }}
      />
      <Footer />
    </>
  );
};

export default Privacy;
